<template>
  <v-col class="d-flex flex-column height-100 pa-0" v-if="priceInfo">
        <v-row class="bb-gray px-7 py-3 ma-0 flex-grow-0 d-flex align-center justify-space-between" style="max-height: 70px; min-height: 70px">
          <span class="fz-16 font-weight-bold text-black" style="margin-left: 2px">
            Salary cost: {{ priceInfo.currencySymbol !== '₽' ? priceInfo.currencySymbol : '' }}{{ +priceInfo.salaryPayments.total.amount | numberFormat }}{{ priceInfo.currencySymbol === '₽' ? priceInfo.currencySymbol : '' }}
          </span>
          <v-icon class="close-icon" @click="$emit('closePriceInfo')">$close</v-icon>
        </v-row>
        <v-row class="ma-0 flex-grow-1 scroll">
          <v-col style="padding: 30px !important;">
            <template v-for="(user, index) in priceInfo.salaryPayments.byDesigner">
              <v-divider v-if="index" :key="'divider' + index" class="my-0"></v-divider>
              <v-btn
                block
                text
                tile
                height="50"
                class="text-none px-0"
                :key="user.designer.id"
                @click="$emit('showDesignerSalary', user)"
              >
                <v-avatar size="32" :color="user.designer.avatarUrl ? 'transparent' : 'primary'">
                  <img
                    v-if="user.designer.avatarUrl"
                      :src="user.designer.avatarUrl"
                    >
                  <span v-else class="fz-14 white--text"> {{ user.designer.businessInitials }}</span>
                </v-avatar>
                <p class="ma-0 ml-2 fz-14 text-black font-weight-bold">{{ user.designer.businessName }}</p>
                <v-spacer></v-spacer>
                <p class="ma-0 fz-14 text-black font-weight-bold">
                  <span class="font-weight-regular">
                    {{ user.paid.symbol !== '₽' ? user.paid.symbol : '' }}{{ +user.paid.amount | numberFormat }}{{ user.paid.symbol === '₽' ? user.paid.symbol : '' }} /</span> {{ user.total.symbol !== '₽' ? user.total.symbol : '' }}{{ +user.total.amount | numberFormat }}{{ user.total.symbol === '₽' ? user.total.symbol : '' }}</p>
              </v-btn>
            </template>
          </v-col>
        </v-row>
      </v-col>
</template>

<script>
import numberFormat from '@/helpers/numberFormat'
export default {
  name: 'SalaryInfo',
  props: ['priceInfo'],
  filters: {
    numberFormat
  }
}
</script>

<style scoped>

</style>
