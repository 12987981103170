<template>
  <v-card style="max-height: 100vh" class="d-flex flex-column height-100 finance-operation">
<!--        header -->
    <v-toolbar
      elevation="0"
      class="bb-gray flex-grow-0"
      style="z-index: 1"
    >
      <v-col>
        <v-row></v-row>
      </v-col>
      <v-col>
        <v-row>
          <v-spacer></v-spacer>
          <v-toolbar-title class="fz-24 font-weight-bold">Billing procedure</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-row>
      </v-col>
      <v-col>
        <v-row>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-icon class="mr-5 close-icon" @click="closeProcedure">$close</v-icon><!-- @click="closeEdit"-->
          </v-toolbar-items>
        </v-row>
      </v-col>
    </v-toolbar>
<!--        main info -->
    <v-card elevation="0" class="py-0 flex-grow-1 scroll">
      <v-row class="ma-0 px-0 pt-10 pb-3">
        <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
        <v-form ref="form" :class="{'px-3': $vuetify.breakpoint.smAndDown}">
          <v-col class="pa-0 min-width-600">
            <v-row class="mx-0 mb-3 mt-12 fz-20 fw-600 text-black">Contract details</v-row>
            <v-divider class="black bb-1 mb-10"></v-divider>
            <v-row class="ma-0">
              <span class="fz-14 text-gray width-140 mt-3 mr-8">Name</span>
              <v-textarea
                outlined
                v-model="billing.name"
                class="rounded-lg fz-14 font-weight-medium input-border text-black"
                height="80"
                no-resize
                :rules="[rules.required]"
              ></v-textarea>
            </v-row>
            <v-row class="ma-0">
              <span class="fz-14 text-gray width-140 mt-3 mr-8">Type of work</span>
              <v-menu attach="#select21" content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100">
                <template v-slot:activator="{ on, attrs }">
                  <v-col class="pa-0">
                    <v-select
                      :items="typesOfWork"
                      :value="billing.typesOfWork"
                      single-line
                      outlined
                      multiple
                      readonly
                      @change="billingGetName"
                      chips
                      item-text="name"
                      item-value="id"
                      class="rounded-lg fz-14 font-weight-medium inline-input select-dash input-border"
                      dense
                      hide-selected
                      :rules="[rules.minCounter]"
                      item-color="#010820"
                      :menu-props="{ bottom: true, offsetY: true }"
                    >
                      <template v-slot:prepend-inner>
                         <v-sheet
                            v-bind="attrs"
                            v-on="on"
                            class="absolute"
                            elevation="0"
                            color="transparent"
                            style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                         ></v-sheet>
                       </template>
                      <template v-slot:selection="data">
                        <v-chip
                          color="#010820"
                          text-color="white"
                          small
                          @click="removeChip(data.item)"
                          style="z-index: 15; border-radius: 6px !important; height: 26px; padding: 0 7px;"
                          label>
                          {{ data.item.name }}
                        </v-chip>
                      </template>
                      <template v-slot:append>
                         <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                       </template>
                    </v-select>
                    <div id="select21" class="relative z-index-400"></div>
                  </v-col>
                </template>
                <v-card class="rounded-lg mt-1 hidden" elevation="0">
                  <v-card-text class="px-0 py-1">
                    <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                      <v-list-item
                        v-for="(item, index) in typesOfWorkArr"
                        :key="index"
                        @click="addTypesOfWork(item)"
                        style="min-height: 30px"
                        class="px-0 py-1"
                      >
                        <p class="ma-0 px-2 fz-12 text-black">{{ item.name }}</p>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-row>
            <v-row class="ma-0">
              <span class="fz-14 text-gray width-140 mt-3 mr-8">Purpose of payment</span>
              <v-text-field
                outlined
                single-line
                @change="billingGetName"
                v-model="billing.purposeOfPayment"
                class="rounded-lg fz-14 font-weight-medium input-border text-black"
                dense
              ></v-text-field>
            </v-row>
            <v-row class="ma-0">
              <span class="fz-14 text-gray width-140 mt-3 mr-8">Amount of payment</span>
              <v-text-field
                outlined
                single-line
                @change="billingGetName"
                v-model="billing.paymentAmount"
                class="rounded-lg fz-14 font-weight-medium inline-input input-border mr-3 text-black"
                dense
                :rules="[rules.required]"
              ></v-text-field>
              <v-menu disabled attach="#select22" content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100">
                <template v-slot:activator="{ on, attrs }">
                  <v-col class="pa-0">
                    <v-text-field
                      outlined
                      single-line
                      :value="billing.paymentCurrency ? billing.paymentCurrency.name : ''"
                      class="rounded-lg mb-n4 text-black fz-14 font-weight-medium select-dash input-border width-120"
                      dense
                      readonly
                      :rules="[rules.required]"
                    >
                      <template v-slot:prepend-inner>
                         <v-sheet
                            v-bind="attrs"
                            v-on="on"
                            class="absolute"
                            elevation="0"
                            color="transparent"
                            style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                         ></v-sheet>
                       </template>
                       <template v-slot:append>
                         <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                       </template>
                    </v-text-field>
                    <div id="select22" class="relative z-index-400"></div>
                  </v-col>
                </template>
                <v-card class="rounded-lg mt-1 hidden" elevation="0">
                  <v-card-text class="px-0 py-1">
                    <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                      <v-list-item
                        v-for="(item, index) in currency"
                        :key="index"
                        @click="getExchange(item, billing, 'paymentAmount', 'paymentCurrency')"
                        style="min-height: 30px"
                        class="px-0 py-1"
                      >
                        <p class="ma-0 px-2 fz-12 text-black">{{ item.name }}</p>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-row>
            <v-row class="ma-0">
              <span class="fz-14 text-gray width-140 mt-3 mr-8">Date of invoice</span>
              <vc-date-picker
                locale="en"
                class="h-full flex-grow-1 z-index-200"
                :class="{'date-picker-orders': $vuetify.breakpoint.mdAndUp, 'date-picker-mobile': $vuetify.breakpoint.smAndDown}"
                :popover="{ visibility: 'click' }"
                :columns="$vuetify.breakpoint.mdAndUp ? 2 : 1"
                mode="date"
                @input="billingGetName"
                :masks="{input: 'DD.MM.YYYY', weekdays: 'WW'}"
                v-model="billing.date">
                <template v-slot="{inputValue}">
                  <v-text-field
                    outlined
                    single-line
                    :value="inputValue"
                    class="rounded-lg fz-14 font-weight-medium input-border text-black"
                    dense
                    :rules="[rules.required]"
                  ></v-text-field>
                </template>
              </vc-date-picker>
            </v-row>
            <v-divider class="mt-1 mb-7"></v-divider>
            <v-row class="ma-0">
              <span style="margin-top: 2px" class="fz-14 text-gray width-140 mr-8">Counterparty details</span>
              <v-col class="pa-0">
                <span class="fz-14 text-gray">INN/KPP</span>
                <v-text-field
                  outlined
                  single-line
                  v-model="companyDetails.inn"
                  @change="companyChanged = true"
                  class="rounded-lg mt-1 fz-14 font-weight-medium input-border text-black"
                  dense
                ></v-text-field>
                <span class="fz-14 text-gray">Legal address</span>
                <v-textarea
                  outlined
                  v-model="companyDetails.legalAddress"
                  @change="companyChanged = true"
                  class="rounded-lg mt-1 fz-14 font-weight-medium input-border text-black"
                  height="80"
                  no-resize
                ></v-textarea>
                <span class="fz-14 text-gray">Postal address (letters)</span>
                <v-textarea
                  outlined
                  v-model="companyDetails.postalAddress"
                  @change="companyChanged = true"
                  class="rounded-lg mt-1 fz-14 font-weight-medium input-border text-black"
                  height="80"
                  no-resize
                ></v-textarea>
                <span class="fz-14 text-gray">Actual address (office)</span>
                <v-textarea
                  outlined
                  v-model="companyDetails.actualAddress"
                  @change="companyChanged = true"
                  class="rounded-lg mt-1 fz-14 font-weight-medium input-border text-black"
                  height="80"
                  no-resize
                ></v-textarea>
                <span class="fz-14 text-gray">OKPO</span>
                <v-text-field
                  outlined
                  single-line
                  v-model="companyDetails.okpo"
                  @change="companyChanged = true"
                  class="rounded-lg mt-1 fz-14 font-weight-medium input-border text-black"
                  dense
                ></v-text-field>
                <span class="fz-14 text-gray">OKVED</span>
                <v-text-field
                  outlined
                  single-line
                  v-model="companyDetails.okved"
                  @change="companyChanged = true"
                  class="rounded-lg mt-1 fz-14 font-weight-medium input-border text-black"
                  dense
                ></v-text-field>
                <span class="fz-14 text-gray">Settlement/Account</span>
                <v-text-field
                  outlined
                  single-line
                  v-model="companyDetails.account"
                  @change="companyChanged = true"
                  class="rounded-lg mt-1 fz-14 font-weight-medium input-border text-black"
                  dense
                ></v-text-field>
                <span class="fz-14 text-gray">Cor./Account</span>
                <v-text-field
                  outlined
                  single-line
                  v-model="companyDetails.corAccount"
                  @change="companyChanged = true"
                  class="rounded-lg mt-1 fz-14 font-weight-medium input-border text-black"
                  dense
                ></v-text-field>
                <span class="fz-14 text-gray">BIK</span>
                <v-text-field
                  outlined
                  single-line
                  v-model="companyDetails.bik"
                  @change="companyChanged = true"
                  class="rounded-lg mt-1 fz-14 font-weight-medium input-border text-black"
                  dense
                ></v-text-field>
                <span class="fz-14 text-gray">FULL NAME CEO</span>
                <v-text-field
                  outlined
                  single-line
                  v-model="companyDetails.fullNameCeo"
                  @change="companyChanged = true"
                  class="rounded-lg mt-1 fz-14 font-weight-medium input-border text-black"
                  dense
                ></v-text-field>
                <span class="fz-14 text-gray">Tel./fax</span>
                <v-text-field
                  outlined
                  single-line
                  v-model="companyDetails.phone"
                  @change="companyChanged = true"
                  class="rounded-lg mt-1 fz-14 font-weight-medium input-border text-black"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-form>
        <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
      </v-row>
    </v-card>
<!--    bottom navigation-->
    <v-row class="ma-0 py-5 px-8 white bt-gray flex-grow-0">
        <v-btn
          width="160"
          height="40"
          class="mr-5 rounded-lg fz-16 fw-600 text-none ml-auto"
          :class="{'ml-n15': $vuetify.breakpoint.smAndDown}"
          elevation="0"
          color="primary"
          @click="saveOperation"
          >
            Save
        </v-btn>
      </v-row>
    <div class="preloader" v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </v-card>
</template>

<script>
import gql from 'graphql-tag'
import { Locale } from 'v-calendar'
const locale = new Locale()

export default {
  name: 'BillingProcedure',
  props: ['orderId', 'priceInfo', 'currency'],
  data () {
    return {
      loading: false,
      billing: {
        name: '',
        date: new Date(),
        typesOfWork: [],
        purposeOfPayment: '',
        paymentAmount: 0,
        paymentCurrency: {
          name: '$ USD',
          value: 'USD'
        }
      },
      companyId: null,
      companyDetails: {},
      companyChanged: false,
      typesOfWork: [],
      rules: {
        required: value => (value !== null && String(value).length > 0) || 'Required.',
        minCounter: value => value ? value.length >= 1 : false || 'Required'
      }
    }
  },
  computed: {
    date: {
      get () {
        return this.billing.date
      },
      set (val) {
        this.billing.date = locale.format(val, 'DD.MM.YYYY')
        this.billingGetName()
      }
    },
    typesOfWorkArr() {
      return this.typesOfWork.filter(el => !this.billing.typesOfWork.includes(el))
    }
  },
  methods: {
    addTypesOfWork(item) {
      this.billing.typesOfWork.push(item)
      this.billingGetName()
    },
    removeChip(item) {
      this.billing.typesOfWork.indexOf(item)
      this.billing.typesOfWork.splice(this.billing.typesOfWork.indexOf(item), 1)
      this.billingGetName()
    },
    closeProcedure() {
      this.$emit('closeProcedure')
    },
    async getExchange (currency, obj, valueAmount, valueCurrency) {
      await this.$apollo.query({
        query: gql`query getOrders ($currency: Currency!, $value: Money! ) {
          pricing {
            convertMoney(currency: $currency, value: $value)
          }
        }`,
        variables: {
          currency: currency.value,
          value: obj[valueAmount] + ' ' + (obj[valueCurrency] ? obj[valueCurrency].value : 'USD')
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        obj[valueAmount] = Math.round(+data.data.pricing.convertMoney.amount * 100) / 100
        obj[valueCurrency] = currency
      }).catch((err) => {
        this.$emit('addSnack', 'item.name', 'Error', err)
        console.log(err)
      })
    },
    async billingGetName () {
      await this.$apollo.query({
        query: gql`query orderInvoiceCreateQuery($input: OrderInvoiceCreateQueryInput! ) {
          orders {
            orderInvoiceCreateQuery(input: $input) {
              name
              amountRub
            }
          }
        }`,
        variables: {
          input: {
            orderId: this.orderId,
            typesOfWork: this.billing.typesOfWork ? this.billing.typesOfWork.map(el => el.id) : ['0'],
            amountOfPayment: (this.billing.paymentAmount ? this.billing.paymentAmount : 0) + ' ' + (this.billing.paymentCurrency ? this.billing.paymentCurrency.value : 'RUB'),
            invoiceDate: this.billing.date ? locale.format(this.billing.date, 'DD.MM.YYYY') : null,
            purposeOfPayment: this.billing.purposeOfPayment ? this.billing.purposeOfPayment : ''
          }
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.billing.name = data.data.orders.orderInvoiceCreateQuery.name
      }).catch((err) => {
        console.log(err)
      })
    },
    async getCompanyInfo () {
      await this.$apollo.query({
        query: gql`query orderInvoiceCreateQuery($id: ID! ) {
          orders {
            order(id: $id) {
              client {
                company {
                  id
                  details {
                    inn
                    legalAddress
                    postalAddress
                    actualAddress
                    okpo
                    okved
                    account
                    corAccount
                    bik
                    fullNameCeo
                    phone
                  }
                }
              }
            }
          }
        }`,
        variables: {
          id: this.orderId
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.companyDetails = data.data.orders.order.client.company.details
        this.companyId = data.data.orders.order.client.company.id
        this.companyChanged = false
      }).catch((err) => {
        console.log(err)
      })
    },
    async getTypesOfWork () {
      if (this.$store.getters.getTypesOfWork) {
        this.typesOfWork = this.$store.getters.getTypesOfWork
      } else {
        await this.$apollo.query({
          query: gql`query getTypesOfWork {
          orders {
            orderInvoiceTypesOfWork {
              name
              id
            }
          }
        }`,
          fetchPolicy: 'no-cache'
        }).then((data) => {
          this.typesOfWork = data.data.orders.orderInvoiceTypesOfWork
          this.$store.commit('updateTypesOfWork', data.data.orders.orderInvoiceTypesOfWork)
        }).catch((err) => {
          this.addSnack('item.name', 'Error', err)
          console.log(err)
        })
      }
    },
    saveOperation() {
      if (this.$refs.form.validate()) {
        if (this.companyChanged) {
          this.updateCompany()
        } else {
          this.createInvoice()
        }
      }
    },
    async updateCompany() {
      await this.$apollo.mutate({
        mutation: gql`mutation updateCompany ($input: CompanyUpdateInput! ) {
          clientele {
            updateCompany(input: $input) {
              id
            }
          }
        }`,
        variables: {
          input: {
            id: this.companyId,
            inn: this.companyDetails.inn,
            legalAddress: this.companyDetails.legalAddress,
            postalAddress: this.companyDetails.postalAddress,
            actualAddress: this.companyDetails.actualAddress,
            okpo: this.companyDetails.okpo,
            okved: this.companyDetails.okved,
            account: this.companyDetails.account,
            corAccount: this.companyDetails.corAccount,
            bik: this.companyDetails.bik,
            fullNameCeo: this.companyDetails.fullNameCeo,
            phone: this.companyDetails.phone
          }
        },
        fetchPolicy: 'no-cache'
      }).then(() => {
        this.createInvoice()
        this.companyChanged = false
      }).catch((err) => {
        console.log(err)
      })
    },
    async createInvoice() {
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`mutation createInvoice ($id: ID!, $input: CreateDesignOrderInvoiceInput! ) {
          orders {
            order(id: $id) {
              createInvoice(input: $input) {
                id
                order {
                  invoices {
                    name
                    amount
                    id
                    downloadUrl
                  }
                }
              }
            }
          }
        }`,
        variables: {
          id: this.orderId,
          input: {
            name: this.billing.name,
            typesOfWorkIds: this.billing.typesOfWork ? this.billing.typesOfWork.map(el => el.id) : ['0'],
            purposeOfPayment: this.billing.purposeOfPayment,
            amount: this.billing.paymentAmount + ' ' + (this.billing.paymentCurrency ? this.billing.paymentCurrency.value : 'RUB'),
            invoiceDate: this.billing.date ? locale.format(this.billing.date, 'DD.MM.YYYY') : null
          }
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.$emit('updateInvoicesList', data.data.orders.order.createInvoice.order.invoices)
        this.billing = {
          name: '',
          date: new Date(),
          typesOfWork: [],
          purposeOfPayment: '',
          paymentAmount: 0,
          paymentCurrency: {
            name: '$ USD',
            value: 'USD'
          }
        }
        this.closeProcedure()
        this.loading = false
      }).catch((err) => {
        console.log(err)
        this.loading = false
      })
    }
  },
  mounted() {
    this.getTypesOfWork()
    this.getCompanyInfo()
  },
  watch: {
    orderId() {
      this.getCompanyInfo()
    }
  }
}
</script>

<style scoped>

</style>
