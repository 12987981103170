<template>
  <v-col class="d-flex flex-column height-100 pa-0" v-if="salaryInfo">
        <v-row class="bb-gray px-7 py-3 ma-0 flex-grow-0 d-flex align-center justify-space-between" style="max-height: 70px; min-height: 70px">
          <v-sheet class="ma-0 pa-0" color="transparent" elevation="0">
            <p class="ma-0 mb-n1 fz-16 font-weight-bold text-black">{{salaryInfo.designer.businessName}}</p>
            <p class="ma-0 fz-14 text-gray text-capitalize">{{salaryInfo.designer.team.toLowerCase()}}</p>
          </v-sheet>
          <v-icon color="#010820" class="rounded-circle chevron-right" @click="$emit('closeSalaryDesignerShow')">mdi-chevron-right</v-icon>
        </v-row>
        <v-row class="ma-0 flex-grow-1 scroll">
          <v-form ref="salaryform" class="flex-grow-1">
            <v-col style="padding: 30px !important;">
              <v-row class="ma-0 flex-grow-0">
                <p class="fz-14 mb-3 text-black font-weight-medium">Paid: {{ salaryInfo.paid.symbol !== '₽' ? salaryInfo.paid.symbol : '' }}{{ +salaryInfo.paid.amount | numberFormat }}{{ salaryInfo.paid.symbol === '₽' ? salaryInfo.paid.symbol : '' }}</p>
                <v-spacer></v-spacer>
                <p class="fz-14 mb-3 text-black font-weight-medium">{{ salaryInfo.total.symbol !== '₽' ? salaryInfo.total.symbol : '' }}{{ +salaryInfo.total.amount | numberFormat }}{{ salaryInfo.total.symbol === '₽' ? salaryInfo.total.symbol : '' }}</p>
              </v-row>
              <v-row class="ma-0 flex-grow-0">
                <v-progress-linear
                  color="primary"
                  rounded
                  height="12"
                  :value="(+salaryInfo.paid.amount / +salaryInfo.total.amount) * 100"
                ></v-progress-linear>
              </v-row>
              <v-row class="ma-0 mt-10 flex-grow-0">
                <span class="fz-14 font-weight-bold">Create payment</span>
              </v-row>
              <v-divider class="my-4"></v-divider>
              <v-row class="ma-0 mb-4 flex-grow-0">
                <v-col class="pa-0">
                  <span class="fz-14 text-gray">Date of payment</span>
                  <v-text-field
                    outlined
                    single-line
                    @click="$emit('datepickerSalaryClick')"
                    :value="createDesignerPayment.date ? createDesignerPayment.date : ''"
                    class="rounded-lg mb-n4 mt-1 fz-14 font-weight-medium input-border"
                    dense
                    readonly
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ma-0 flex-grow-0">
                <v-col class="pa-0 pr-3">
                  <span class="fz-14 text-gray">Payment</span>
                  <v-text-field
                    v-model.number="createDesignerPayment.amount"
                    type="number"
                    outlined
                    hide-details
                    class="input-qty rounded-lg mt-1 input-border fz-14 font-weight-medium text-black"
                    dense
                  >
                    <template v-slot:append>
                        <v-menu :disabled="true" attach="#select2" content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 right-0 min-width-70">
                          <template v-slot:activator="{ on, attrs }">
                            <v-col class="pa-0">
                              <v-sheet
                                v-bind="attrs"
                                v-on="on"
                                elevation="0"
                                outlined
                                height="24"
                                class="text-none text-black fz-14 font-weight-medium border-none rounded-lg d-flex align-center payment-currency justify-space-between"
                              >
                                  {{ createDesignerPayment.currency }}
                                  <v-icon size="20" class="path-black">$chevronDown</v-icon>
                              </v-sheet>
                              <div id="select2" class="relative z-index-400"></div>
                            </v-col>
                          </template>
                          <v-card class="rounded-lg mt-1 hidden" elevation="0">
                            <v-card-text class="px-0 py-1">
                              <v-list class="pa-0 pl-1" color="transparent scroll" style="max-height: 200px">
                                <v-list-item
                                  v-for="(item, index) in ['USD', 'RUB', 'EUR']"
                                  :key="index"
                                  @click="setPayment(item)"
                                  style="min-height: 30px"
                                  class="px-0 py-1 ma-0 justify-center"
                                >
                                  <p class="ma-0 px-2 fz-12 text-black">{{ item }}</p>
                                </v-list-item>
                              </v-list>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col class="pa-0 pl-3">
                  <span class="fz-14 text-gray">Extra Fee</span>
                  <v-text-field
                    v-model.number="createDesignerPayment.extraFee"
                    type="number"
                    outlined
                    hide-details
                    class="input-qty rounded-lg mt-1 input-border fz-14 font-weight-medium text-black"
                    dense
                  >
                    <template v-slot:append>
                        <v-menu :disabled="true" attach="#select3" content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 right-0 min-width-70">
                          <template v-slot:activator="{ on, attrs }">
                            <v-col class="pa-0">
                              <v-sheet
                                v-bind="attrs"
                                v-on="on"
                                elevation="0"
                                outlined
                                height="24"
                                class="text-none text-black fz-14 font-weight-medium border-none rounded-lg d-flex align-center payment-currency justify-space-between"
                              >
                                  {{ createDesignerPayment.extraFeeCurrency }}
                                  <v-icon size="20" class="path-black">$chevronDown</v-icon>
                              </v-sheet>
                              <div id="select3" class="relative z-index-400"></div>
                            </v-col>
                          </template>
                          <v-card class="rounded-lg mt-1 hidden" elevation="0">
                            <v-card-text class="px-0 py-1">
                              <v-list class="pa-0 pl-1" color="transparent scroll" style="max-height: 200px">
                                <v-list-item
                                  v-for="(item, index) in ['USD', 'RUB', 'EUR']"
                                  :key="index"
                                  @click="createDesignerPayment.extraFeeCurrency = item"
                                  style="min-height: 30px"
                                  class="px-0 py-1 ma-0 justify-center"
                                >
                                  <p class="ma-0 px-2 fz-12 text-black">{{ item }}</p>
                                </v-list-item>
                              </v-list>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="ma-0 mt-4 flex-grow-0">
                <v-col class="pa-0">
                  <span class="fz-14 text-gray">Account</span>
                  <v-menu attach="#select6" content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100">
                      <template v-slot:activator="{ on, attrs }">
                        <v-col class="pa-0">
                          <v-text-field
                            outlined
                            single-line
                            :value="createDesignerPayment.account ? createDesignerPayment.account.name : ''"
                            class="rounded-lg text-black mb-n1 mt-1 fz-14 font-weight-medium select-dash input-border"
                            dense
                            readonly
                            :rules="[rules.required]"
                          >
                            <template v-slot:prepend-inner>
                               <v-sheet
                                v-bind="attrs"
                                v-on="on"
                                class="absolute"
                                elevation="0"
                                color="transparent"
                                style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                               ></v-sheet>
                             </template>
                             <template v-slot:append>
                               <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                             </template>
                          </v-text-field>
                          <div id="select6" class="relative z-index-400"></div>
                        </v-col>
                      </template>
                      <v-card class="rounded-lg mt-1 hidden" elevation="0">
                        <v-card-text class="px-0 py-1">
                          <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                            <v-list-item
                              v-for="(item, index) in paymentAccounts"
                              :key="index"
                              @click="createDesignerPayment.account = item"
                              style="min-height: 30px"
                              class="px-0 py-1"
                            >
                              <p class="ma-0 px-2 fz-12 text-black">{{ item.name }}</p>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                </v-col>
              </v-row>
              <v-row class="ma-0 flex-grow-0">
                <span class="fz-14 font-weight-bold">Paid up</span>
              </v-row>
              <v-divider></v-divider>
              <v-row class="ma-0 mb-4 flex-grow-0" v-for="(payment, index) in salaryInfo.operations" :key="'payment' + index">
                <v-col class="pa-0">
                  <span class="fz-14 text-gray">Date of payment</span>
                  <v-text-field
                    :value="formatDate(payment.date)"
                    readonly
                    filled
                    hide-details
                    class="rounded-lg mt-1 border-none fz-14 font-weight-medium text-black"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col class="py-0 px-3">
                  <span class="fz-14 text-gray">Payment</span>
                  <v-text-field
                    :value="paymentValue(payment.amount.amount, payment.amount.symbol)"
                    readonly
                    filled
                    hide-details
                    class="rounded-lg mt-1 border-none fz-14 font-weight-medium text-black"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="1" class="pa-0">
                  <v-row class="ma-0 height-100">
                    <v-dialog
                    transition="dialog-bottom-transition"
                    max-width="370"
                    content-class="rounded-xl"
                    overlay-color="rgba(9, 24, 73, 0.2)"
                    overlay-opacity="1"
                  >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        elevation="0"
                        x-small
                        icon
                        fab
                        class="mt-auto mb-1"
                      >
                        <v-icon size="20"  class="stroke-gray">$delete</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:default="dialog">
                      <v-card class="pa-7">
                        <v-card-text class="py-0">
                          <div class="text-black font-weight-medium fz-18 text-center">Do you really want to delete payment {{ payment.amount.symbol !== '₽' ? payment.amount.symbol : '' }}{{ +payment.amount.amount | numberFormat }}{{ payment.amount.symbol === '₽' ? payment.amount.symbol : '' }}?</div>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions class="justify-space-between pa-0">
                          <v-btn
                            class="rounded-lg text-none fz-16 fw-600"
                            outlined
                            width="150"
                            height="40"
                            @click="dialog.value = false"
                          >Return</v-btn>
                          <v-btn
                            elevation="0"
                            color="#FF0000"
                            width="150"
                            height="40"
                            class="rounded-lg white--text text-none fz-16 fw-600"
                            @click="$emit('removePayment', payment.id)"
                          >
                            <v-icon left class="stroke-white">
                              $delete
                            </v-icon>
                            Delete</v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-form>
        </v-row>
        <v-row class="ma-0 flex-grow-0" style="padding: 30px 0 !important;">
          <v-btn elevation="0" @click="$emit('closePriceInfo')" color="primary" height="40" width="155" class="ml-7 mr-3 rounded-lg fz-16 fw-600 text-none" outlined>Cancel</v-btn>
          <v-btn
            elevation="0"
            @click="$emit('updateDesignerPayment')"
            :disabled="createDesignerPaymentBtnActive"
            color="primary"
            height="40"
            width="155"
            class="rounded-lg fz-16 fw-600 text-none"
          >Save</v-btn>
        </v-row>
      </v-col>
</template>

<script>
import { Locale } from 'v-calendar'
import numberFormat from '@/helpers/numberFormat'
import gql from 'graphql-tag'
const locale = new Locale()
export default {
  name: 'SalaryOperation',
  props: ['salaryInfo', 'priceInfo', 'createDesignerPayment', 'paymentAccounts', 'currency'],
  filters: {
    numberFormat
  },
  data() {
    return {
      rules: {
        required: value => (value !== null && String(value).length > 0) || 'Required.'
      }
    }
  },
  computed: {
    createDesignerPaymentBtnActive () {
      if (String(this.createDesignerPayment.amount).trim().length > 0 &&
        String(this.createDesignerPayment.date).length > 0 &&
        +this.createDesignerPayment.amount > 0 &&
        (+this.createDesignerPayment.amount <= +this.salaryInfo.total.amount - +this.salaryInfo.paid.amount)) return false
      else return true
    }
  },
  methods: {
    formatDate (date) {
      return locale.format(date, 'DD.MM.YYYY')
    },
    paymentValue (amount, symbol) {
      return (symbol !== '₽' ? symbol : '') + this.$options.filters.numberFormat(+amount) + (symbol === '₽' ? symbol : '')
    },
    setPayment(currency) {
      this.getExchange(currency, this.createDesignerPayment)
      this.getExchange(currency, this.salaryInfo.total)
      this.getExchange(currency, this.salaryInfo.paid)
    },
    async getExchange (currency, obj, valueAmount = 'amount', valueCurrency = 'currency') {
      await this.$apollo.query({
        query: gql`query getOrders ($currency: Currency!, $value: Money! ) {
          pricing {
            convertMoney(currency: $currency, value: $value)
          }
        }`,
        variables: {
          currency: currency,
          value: obj[valueAmount] + ' ' + obj[valueCurrency]
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        obj[valueAmount] = Math.round(+data.data.pricing.convertMoney.amount * 100) / 100
        obj[valueCurrency] = data.data.pricing.convertMoney.currency
      }).catch((err) => {
        this.$emit('addSnack', 'item.name', 'Error', err)
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

</style>
