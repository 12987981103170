<template>
  <v-col class="d-flex flex-column height-100 pa-0" v-if="priceInfo">
    <v-row class="bb-gray px-7 py-3 ma-0 flex-grow-0 d-flex align-center justify-space-between" style="max-height: 70px; min-height: 70px">
      <span class="fz-16 font-weight-bold text-black" style="margin-left: 2px">
        {{ type === 'invoice' ? 'Invoices for payment' : 'Completion reports' }}
      </span>
      <v-icon color="#010820" class="rounded-circle chevron-right" @click="$emit('closePaymentAdditional')">mdi-chevron-right</v-icon>
    </v-row>
    <v-row class="ma-0 flex-grow-1 scroll">
      <v-col v-if="type === 'invoice'" class="scroll height-100" style="padding: 30px !important;">
        <template v-for="(invoice, index) in priceInfo.invoices">
          <v-divider v-if="index" :key="invoice.id + 'divider'"></v-divider>
          <v-row :key="invoice.id" class="ma-0 d-flex flex-nowrap justify-space-between">
            <p class="ma-0 mr-2 fz-14 font-weight-medium text-black">{{ invoice.name }}</p>
            <v-dialog
                transition="dialog-bottom-transition"
                max-width="370"
                content-class="rounded-xl"
                overlay-color="rgba(9, 24, 73, 0.2)"
                overlay-opacity="1"
              >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  elevation="0"
                  x-small
                  icon
                  fab
                  class="my-auto"
                >
                  <v-icon size="20"  class="stroke-gray">$delete</v-icon>
                </v-btn>
              </template>
              <template v-slot:default="dialog">
                <v-card class="pa-7">
                  <v-card-text class="py-0">
                    <div class="text-black font-weight-medium fz-18 text-center">Do you really want to delete
                      {{ invoice.name }}?</div>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="justify-space-between pa-0">
                    <v-btn
                      class="rounded-lg text-none fz-16 fw-600"
                      outlined
                      width="150"
                      height="40"
                      @click="dialog.value = false"
                    >Return</v-btn>
                    <v-btn
                      elevation="0"
                      color="#FF0000"
                      width="150"
                      height="40"
                      class="rounded-lg white--text text-none fz-16 fw-600"
                      @click="deleteInvoice(invoice)"
                    >
                      <v-icon left class="stroke-white">
                        $delete
                      </v-icon>
                      Delete</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
            <a class="my-auto" target="_blank" :href="invoice.downloadUrl"><v-icon class="stroke-black">$paymentReportDownload</v-icon></a>
          </v-row>
        </template>
      </v-col>
      <v-col v-else class="scroll height-100" style="padding: 30px !important;">
        <template v-for="(completionAct, index) in priceInfo.completionActs">
          <v-divider v-if="index" :key="completionAct.id + 'divider'"></v-divider>
          <v-row :key="index" class="ma-0 d-flex flex-nowrap justify-space-between">
            <p class="ma-0 mr-2 fz-14 font-weight-medium text-black">{{ completionAct.name }}</p>
            <v-dialog
                transition="dialog-bottom-transition"
                max-width="370"
                content-class="rounded-xl"
                overlay-color="rgba(9, 24, 73, 0.2)"
                overlay-opacity="1"
              >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  elevation="0"
                  x-small
                  icon
                  fab
                  class="my-auto"
                >
                  <v-icon size="20"  class="stroke-gray">$delete</v-icon>
                </v-btn>
              </template>
              <template v-slot:default="dialog">
                <v-card class="pa-7">
                  <v-card-text class="py-0">
                    <div class="text-black font-weight-medium fz-18 text-center">Do you really want to delete
                      {{ completionAct.name }}?</div>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="justify-space-between pa-0">
                    <v-btn
                      class="rounded-lg text-none fz-16 fw-600"
                      outlined
                      width="150"
                      height="40"
                      @click="dialog.value = false"
                    >Return</v-btn>
                    <v-btn
                      elevation="0"
                      color="#FF0000"
                      width="150"
                      height="40"
                      class="rounded-lg white--text text-none fz-16 fw-600"
                      @click="deleteReport(completionAct); dialog.value = false"
                    >
                      <v-icon left class="stroke-white">
                        $delete
                      </v-icon>
                      Delete</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
            <a class="my-auto" target="_blank" :href="completionAct.downloadUrl"><v-icon class="stroke-black">$paymentReportDownload</v-icon></a>
          </v-row>
        </template>
      </v-col>
    </v-row>
    <v-row class="ma-0 flex-grow-0" style="padding: 30px !important;">
      <v-btn
        v-if="type === 'invoice'"
        elevation="0"
        color="#091849"
        height="40"
        block
        dark
        class="rounded-lg fz-14 fw-600 text-none"
        @click="showFinanceProcedure"
      >
        <v-icon size="19" class="mr-1">mdi-plus</v-icon>
        Generate invoice payment
      </v-btn>
      <v-btn
        v-else
        elevation="0"
        color="#091849"
        height="40"
        block
        dark
        class="rounded-lg fz-14 fw-600 text-none"
        @click="OpenCompletionReports"
      >
        <v-icon size="19" class="mr-1">mdi-plus</v-icon>
        Generate Completion reports
      </v-btn>
    </v-row>
    <v-dialog
        v-model="financeProcedureShow"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        class="z-index-400"
        overlay-color="rgba(9, 24, 73, 0.2)"
        overlay-opacity="1"
      >
    <billing-procedure
      ref="billingProcedure"
      :orderId="priceInfo.id"
      :priceInfo="priceInfo"
      :currency="currency"
      @updateInvoicesList="updateInvoicesList"
      @closeProcedure="closeProcedure"
      />
    </v-dialog>
    <v-dialog
        transition="dialog-bottom-transition"
        content-class="rounded-4"
        max-width="560"
        v-model="completionReportsShow"
        overlay-color="rgba(9, 24, 73, 0.2)"
        overlay-opacity="1"
      >
        <template v-slot:default="">
          <v-card class="rounded-4">
            <v-toolbar
              height="70"
              elevation="0"
              class="bb-gray"
            >
              <p class="fz-16 font-weight-bold text-black ff ma-0 ml-3 pa-0">Generate Completion reports</p>
              <v-spacer></v-spacer>
              <v-icon class="close-icon" @click="closeCompletionReports">$close</v-icon>
            </v-toolbar>
            <v-card-text>
              <v-col class="px-1">
                <v-form ref="form">
                  <p class="fz-14 text-gray mb-1">
                    Invoice
                  </p>
                  <v-menu attach="#select15" content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100">
                    <template v-slot:activator="{ on, attrs }">
                      <v-col class="pa-0">
                        <v-text-field
                          outlined
                          single-line
                          :value="invoiceSelected ? invoiceSelected.name : ''"
                          class="rounded-lg text-black mb-n1 mt-1 fz-14 font-weight-medium select-dash input-border"
                          dense
                          readonly
                          :rules="[rules.required]"
                        >
                          <template v-slot:prepend-inner>
                             <v-sheet
                              v-bind="attrs"
                              v-on="on"
                              class="absolute"
                              elevation="0"
                              color="transparent"
                              style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                             ></v-sheet>
                           </template>
                           <template v-slot:append>
                             <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                           </template>
                        </v-text-field>
                        <div id="select15" class="relative z-index-400"></div>
                      </v-col>
                    </template>
                    <v-card class="rounded-lg mt-1 hidden" elevation="0">
                      <v-card-text class="px-0 py-1">
                        <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                          <v-list-item
                            v-for="(item, index) in listInvoices"
                            :key="index"
                            @click="invoiceSelected = item"
                            style="min-height: 30px"
                            class="px-0 py-1"
                          >
                            <p class="ma-0 px-2 fz-12 text-black">{{ item.name }}</p>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                  <p class="fz-14 text-gray mb-1">
                    Date
                  </p>
                  <vc-date-picker
                    locale="en"
                    class="h-full flex-grow-1"
                    :class="{'date-picker-orders': $vuetify.breakpoint.mdAndUp, 'date-picker-mobile': $vuetify.breakpoint.smAndDown}"
                    :popover="{ visibility: 'click' }"
                    :columns="$vuetify.breakpoint.mdAndUp ? 2 : 1"
                    mode="date"
                    :masks="{input: 'DD.MM.YYYY', weekdays: 'WW'}"
                    v-model="reportDate">
                    <template v-slot="{inputValue}">
                      <v-text-field
                        outlined
                        single-line
                        :value="inputValue"
                        class="rounded-lg fz-14 mb-3 font-weight-medium input-border text-black"
                        dense
                        :rules="[rules.required]"
                      ></v-text-field>
                    </template>
                  </vc-date-picker>
                  <v-row class="ma-0 pa-0">
                    <v-spacer></v-spacer>
                    <v-btn
                      elevation="0"
                      color="primary"
                      class="rounded-lg fz-16 fw-600 text-none ml-auto"
                      width="155"
                      @click="createReport"
                    >Send</v-btn>
                  </v-row>
                </v-form>
              </v-col>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
  </v-col>
</template>

<script>
import BillingProcedure from '@/components/App/Finance/BillingProcedure'
import { mapMutations } from 'vuex'
import gql from 'graphql-tag'
import { Locale } from 'v-calendar'
const locale = new Locale()

export default {
  name: 'PaymentsInvoicesReports',
  components: { BillingProcedure },
  props: ['priceInfo', 'type'],
  data() {
    return {
      financeProcedureShow: false,
      completionReportsShow: false,
      invoiceSelected: null,
      reportDate: new Date(),
      rules: {
        required: value => (value !== null && String(value).length > 0) || 'Required.'
      },
      currency: [
        {
          name: '$ (USD)',
          value: 'USD'
        },
        {
          name: '€ (EUR)',
          value: 'EUR'
        },
        {
          name: '₽ (RUB)',
          value: 'RUB'
        }
      ]
    }
  },
  computed: {
    listInvoices() {
      return this.priceInfo.invoices.filter(el => !(this.priceInfo.completionActs.find(e => e.invoice.id === el.id)))
    }
  },
  methods: {
    ...mapMutations(['showSnackbar']),
    showFinanceProcedure() {
      this.financeProcedureShow = true
      const self = this
      setTimeout(function () {
        if (+self.priceInfo.paymentRemainder > 0) {
          self.$refs.billingProcedure.billing.paymentAmount = +self.priceInfo.paymentRemainder
          self.$refs.billingProcedure.billing.paymentCurrency = self.currency.find(el => el.value === self.priceInfo.currency)
        }
      })
    },
    closeProcedure() {
      this.financeProcedureShow = false
    },
    OpenCompletionReports() {
      this.completionReportsShow = true
    },
    closeCompletionReports() {
      this.$refs.form.resetValidation()
      this.completionReportsShow = false
    },
    updateInvoicesList(list) {
      this.priceInfo.invoices = list
    },
    async createReport () {
      if (this.$refs.form.validate()) {
        await this.$apollo.mutate({
          mutation: gql`mutation createInvoice ($id: ID!, $input: CreateDesignOrderCompletionActInput!) {
            orders {
              order(id: $id) {
                createCompletionAct(input: $input) {
                  order {
                    completionActs {
                      id
                      downloadUrl
                      name
                      invoice {
                        id
                      }
                    }
                  }
                }
              }
            }
          }`,
          variables: {
            id: this.priceInfo.id,
            input: {
              invoiceId: this.invoiceSelected.id,
              date: locale.format(this.reportDate, 'DD.MM.YYYY')
            }
          },
          fetchPolicy: 'no-cache'
        }).then((data) => {
          this.priceInfo.completionActs = data.data.orders.order.createCompletionAct.order.completionActs
          this.invoiceSelected = null
          this.reportDate = new Date()
          this.showSnackbar(['Success', 'Completion Act was created with success'])
          this.$emit('getOrders')
          this.closeCompletionReports()
        }).catch((err) => {
          console.log(err, 'createReport')
        })
      }
    },
    async deleteInvoice (invoice) {
      await this.$apollo.mutate({
        mutation: gql`mutation deleteInvoice($id: ID!, $invoiceId: ID! ) {
          orders {
            order(id: $id) {
              deleteInvoice(invoiceId: $invoiceId) {
                id
              }
            }
          }
        }`,
        variables: {
          id: this.priceInfo.id,
          invoiceId: invoice.id
        }
      }).then(() => {
        this.showSnackbar(['Success', 'Invoice ' + invoice.name + '  was deleted with success'])
        this.priceInfo.invoices.splice(this.priceInfo.invoices.indexOf(invoice), 1)
      }).catch((err) => {
        this.showSnackbar(['Error', err])
        console.log(err)
      })
    },
    async deleteReport (report) {
      await this.$apollo.mutate({
        mutation: gql`mutation deleteInvoice($id: ID!, $completionActId: ID! ) {
          orders {
            order(id: $id) {
              deleteCompletionAct(completionActId: $completionActId) {
                id
              }
            }
          }
        }`,
        variables: {
          id: this.priceInfo.id,
          completionActId: report.id
        }
      }).then(() => {
        this.showSnackbar(['Success', 'Completion act was deleted with success'])
        this.priceInfo.completionActs.splice(this.priceInfo.completionActs.indexOf(report), 1)
      }).catch((err) => {
        this.showSnackbar(['Error', err])
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

</style>
