<template>
<v-col class="d-flex flex-column height-100 pa-0" v-if="priceInfo">
        <v-row class="bb-gray px-7 py-3 ma-0 flex-grow-0 d-flex align-center justify-space-between" style="max-height: 70px; min-height: 70px">
          <span class="fz-16 font-weight-bold text-black" style="margin-left: 2px">
            Final price of the project {{ priceInfo.currencySymbol !== '₽' ? priceInfo.currencySymbol : '' }}{{ +priceInfo.totalCost | numberFormat }}{{ priceInfo.currencySymbol === '₽' ? priceInfo.currencySymbol : '' }}
          </span>
          <v-icon class="close-icon" @click="$emit('closePriceInfo')">$close</v-icon>
        </v-row>
        <v-row class="ma-0 flex-grow-1 scroll">
          <v-form ref="form" class="flex-grow-1">
            <v-col style="padding: 30px !important;">
              <v-row class="ma-0 flex-grow-0">
                <p class="fz-14 mb-3 text-black font-weight-medium">Paid: {{ priceInfo.currencySymbol !== '₽' ? priceInfo.currencySymbol : '' }}{{ +priceInfo.paidCurrently | numberFormat }}{{ priceInfo.currencySymbol === '₽' ? priceInfo.currencySymbol : '' }}</p>
                <v-spacer></v-spacer>
                <p class="fz-14 mb-3 text-black font-weight-medium">{{ priceInfo.currencySymbol !== '₽' ? priceInfo.currencySymbol : '' }}{{ +priceInfo.totalCost | numberFormat }}{{ priceInfo.currencySymbol === '₽' ? priceInfo.currencySymbol : '' }}</p>
              </v-row>
              <v-row class="ma-0 flex-grow-0">
                <v-progress-linear
                  color="primary"
                  rounded
                  height="12"
                  :value="(+priceInfo.paidCurrently / +priceInfo.totalCost) * 100"
                ></v-progress-linear>
              </v-row>
              <v-row class="ma-0 mt-10 flex-grow-0">
                <span class="fz-14 font-weight-bold">Create payment</span>
              </v-row>
              <v-divider class="my-4"></v-divider>
              <v-row class="ma-0 flex-grow-0">
                <v-col class="pa-0">
                  <span class="fz-14 text-gray">Date of payment</span>
                  <vc-date-picker
                      class="inline-block h-full"
                      locale="en"
                      :class="{'date-picker-edit-designer': $vuetify.breakpoint.mdAndUp, 'date-picker-mobile': $vuetify.breakpoint.smAndDown, 'date-picker-sidebar-payment': $vuetify.breakpoint.smAndDown }"
                      :popover="{ visibility: 'click' }"
                      :columns="$vuetify.breakpoint.mdAndUp ? 2 : 1"
                      mode="date"
                      :max-date='new Date()'
                      :masks="{input: 'DD.MM.YYYY', weekdays: 'WW'}"
                      v-model="createPaymentDate">
                      <template v-slot="">
                        <v-text-field
                          outlined
                          single-line
                          :value="createPayment.date"
                          class="rounded-lg mb-n4 mt-1 fz-14 font-weight-medium input-border"
                          dense
                          readonly
                        ></v-text-field>
                      </template>
                    </vc-date-picker>
                </v-col>
                <v-col class="py-0 px-3">
                  <span class="fz-14 text-gray">Payment</span>
                  <v-text-field
                    v-model.number="createPayment.amount"
                    type="number"
                    outlined
                    hide-details
                    class="input-qty rounded-lg mt-1 input-border fz-14 font-weight-medium text-black"
                    dense
                  >
                    <template v-slot:append>
                      <v-menu disabled attach="#select1" content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 right-0 min-width-70">
                        <template v-slot:activator="{ on, attrs }">
                          <v-col class="pa-0">
                            <v-sheet
                              v-bind="attrs"
                              v-on="on"
                              elevation="0"
                              outlined
                              height="24"
                              class="text-none text-black fz-14 font-weight-medium border-none d-flex align-center payment-currency justify-space-between"
                            >
        <!--                      <v-row class="ma-0">-->
                                {{ createPayment.currency }}
        <!--                      </v-row>-->
        <!--                      <div class="d-flex">-->
                                <v-icon size="20" class="path-black">$chevronDown</v-icon>
        <!--                      </div>-->
                            </v-sheet>
                            <div id="select1" class="relative z-index-400"></div>
                          </v-col>
                        </template>
                        <v-card class="rounded-lg mt-1 hidden" elevation="0">
                          <v-card-text class="px-0 py-1">
                            <v-list class="pa-0 pl-1" color="transparent scroll" style="max-height: 200px">
                              <v-list-item
                                v-for="(item, index) in ['USD', 'RUB', 'EUR']"
                                :key="index"
                                @click="setPayment(item)"
                                style="min-height: 30px"
                                class="px-0 py-1 ma-0 justify-center"
                              >
                                <p class="ma-0 px-2 fz-12 text-black">{{ item }}</p>
                              </v-list-item>
                            </v-list>
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="ma-0 mt-4 flex-grow-0">
                <v-col class="pa-0">
                  <span class="fz-14 text-gray">Account</span>
                  <v-menu attach="#select4" content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100">
                    <template v-slot:activator="{ on, attrs }">
                      <v-col class="pa-0">
                        <v-text-field
                          outlined
                          single-line
                          :value="createPayment.account ? createPayment.account.name : ''"
                          class="rounded-lg text-black mb-n1 mt-1 fz-14 font-weight-medium select-dash input-border"
                          dense
                          readonly
                          :rules="[rules.required]"
                        >
                          <template v-slot:prepend-inner>
                             <v-sheet
                              v-bind="attrs"
                              v-on="on"
                              class="absolute"
                              elevation="0"
                              color="transparent"
                              style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                             ></v-sheet>
                           </template>
                           <template v-slot:append>
                             <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                           </template>
                        </v-text-field>
                        <div id="select4" class="relative z-index-400"></div>
                      </v-col>
                    </template>
                    <v-card class="rounded-lg mt-1 hidden" elevation="0">
                      <v-card-text class="px-0 py-1">
                        <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                          <v-list-item
                            v-for="(item, index) in paymentAccounts"
                            :key="index"
                            @click="createPayment.account = item"
                            style="min-height: 30px"
                            class="px-0 py-1"
                          >
                            <p class="ma-0 px-2 fz-12 text-black">{{ item.name }}</p>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                  <span v-if="priceInfo.invoices && priceInfo.invoices.length > 0" class="fz-14 text-gray">Invoice</span>
                  <v-menu attach="#select5"
                          v-if="priceInfo.invoices && priceInfo.invoices.length > 0"
                          content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100">
                    <template v-slot:activator="{ on, attrs }">
                      <v-col class="pa-0">
                        <v-text-field
                          outlined
                          single-line
                          :value="invoiceSelected ? invoiceSelected.name : ''"
                          class="rounded-lg text-black mb-n1 mt-1 fz-14 font-weight-medium select-dash input-border"
                          dense
                          readonly
                        >
                          <template v-slot:prepend-inner>
                             <v-sheet
                              v-bind="attrs"
                              v-on="on"
                              class="absolute"
                              elevation="0"
                              color="transparent"
                              style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                             ></v-sheet>
                           </template>
                           <template v-slot:append>
                             <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                           </template>
                        </v-text-field>
                        <div id="select5" class="relative z-index-400"></div>
                      </v-col>
                    </template>
                    <v-card class="rounded-lg mt-1 hidden" elevation="0">
                      <v-card-text class="px-0 py-1">
                        <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                          <v-list-item
                            v-for="(item, index) in priceInfo.invoices"
                            :key="index"
                            @click="$emit('changePaymentFromInvoice', item)"
                            style="min-height: 30px"
                            class="px-0 py-1"
                          >
                            <p class="ma-0 px-2 fz-12 text-black">{{ item.name }}</p>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="ma-0 flex-grow-1"></v-row>
              <v-row class="mx-0 flex-grow-0">
                <v-col class="pa-0">
                  <v-divider class="my-0"></v-divider>
                  <v-btn
                    :ripple="false"
                    block
                    text
                    tile
                    height="50"
                    class="text-none right-sidebar-btn px-0"
                    :disabled="priceHistoryShow"
                    @click="$emit('openPriceHistory')"
                  >Payments history
                    <v-spacer></v-spacer>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                  <v-divider class="my-0"></v-divider>
                  <v-btn
                    block
                    :ripple="false"
                    text
                    tile
                    height="50"
                    class="text-none right-sidebar-btn px-0"
                    :disabled="priceInvoicesShow"
                    @click="$emit('openPriceInvoices')"
                  >Invoices for payment
                    <v-spacer></v-spacer>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                  <v-divider class="my-0"></v-divider>
                  <v-btn
                    block
                    :ripple="false"
                    text
                    tile
                    height="50"
                    class="text-none right-sidebar-btn px-0"
                    :disabled="priceReportsShow"
                    @click="$emit('openPriceReports')"
                  >Completion reports
                    <v-spacer></v-spacer>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                  <v-divider class="my-0"></v-divider>
                  <v-sheet elevation="0" height="50" class="d-flex" v-if="priceInfo.contractUrl">
                    <p class="mx-0 my-auto fz-14 text-black font-weight-medium one-line" style="max-width: 204px">{{ priceInfo.contractTitle ? priceInfo.contractTitle : priceInfo.contractUrl }}</p>

                    <v-dialog
                        transition="dialog-bottom-transition"
                        max-width="370"
                        content-class="rounded-xl"
                        overlay-color="rgba(9, 24, 73, 0.2)"
                        overlay-opacity="1"
                      >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          elevation="0"
                          x-small
                          icon
                          fab
                          class="my-auto"
                        >
                          <v-icon size="20" class="stroke-gray">$delete</v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:default="dialog">
                        <v-card class="pa-7">
                          <v-card-text class="py-0">
                            <div class="text-black font-weight-medium fz-18 text-center">Do you really want to delete contract?</div>
                          </v-card-text>
                          <v-divider></v-divider>
                          <v-card-actions class="justify-space-between pa-0">
                            <v-btn
                              class="rounded-lg text-none fz-16 fw-600"
                              outlined
                              width="150"
                              height="40"
                              @click="dialog.value = false"
                            >Return</v-btn>
                            <v-btn
                              elevation="0"
                              color="#FF0000"
                              width="150"
                              height="40"
                              class="rounded-lg white--text text-none fz-16 fw-600"
                              @click="$emit('deleteContract')"
                            >
                              <v-icon left class="stroke-white">
                                $delete
                              </v-icon>
                              Delete</v-btn>
                          </v-card-actions>
                        </v-card>
                      </template>
                    </v-dialog>
                    <v-spacer></v-spacer>
                    <a :href="priceInfo.contractUrl" target="_blank" class="my-auto">
                      <v-icon class="stroke-primary">$paymentContract</v-icon>
                    </a>
                  </v-sheet>
                  <v-btn
                    v-else
                    block
                    :ripple="false"
                    text
                    tile
                    height="50"
                    class="text-none right-sidebar-btn px-0"
                    @click="$emit('addContract')"
                  >Attach a contract
                    <v-spacer></v-spacer>
                    <v-icon class="stroke-lgray">$paymentContract</v-icon>
                  </v-btn>
                  <v-divider class="my-0"></v-divider>
                </v-col>
              </v-row>
            </v-col>
          </v-form>
        </v-row>
        <v-row class="ma-0 flex-grow-0" style="padding: 30px 0 !important;">
          <v-btn elevation="0" @click="$emit('closePriceInfo')" color="primary" height="40" width="155" class="ml-7 mr-3 rounded-lg fz-16 fw-600 text-none" outlined>Cancel</v-btn>
          <v-btn
            elevation="0"
            @click="$emit('updatePayment')"
            :disabled="createPaymentBtnActive"
            color="primary"
            height="40"
            width="155"
            class="rounded-lg fz-16 fw-600 text-none"
          >Save</v-btn>
        </v-row>
      </v-col>
</template>

<script>
import numberFormat from '@/helpers/numberFormat'
import { Locale } from 'v-calendar'
import gql from 'graphql-tag'
const locale = new Locale()

export default {
  name: 'PriceInfo',
  props: ['priceInfo', 'createPayment', 'paymentAccounts', 'priceHistoryShow', 'priceInvoicesShow', 'priceReportsShow', 'paymentRemainder', 'invoiceSelected'],
  filters: {
    numberFormat
  },
  data() {
    return {
      rules: {
        required: value => (value !== null && String(value).length > 0) || 'Required.'
      }
    }
  },
  computed: {
    createPaymentDate: {
      get () {
        if (String(this.createPayment.date).length === 0) return new Date()
        else return this.createPayment.date
      },
      set (val) {
        this.createPayment.date = locale.format(val, 'DD.MM.YYYY')
      }
    },
    createPaymentBtnActive () {
      if (String(this.createPayment.amount).trim().length > 0 &&
        String(this.createPayment.date).length > 0 &&
        +this.createPayment.amount > 0 &&
        (+this.createPayment.amount <= +this.paymentRemainder.amount)) return false
      else return true
    }
  },
  methods: {
    setPayment(currency) {
      this.getExchange(currency, this.createPayment, 'amount', 'currency')
      this.getExchange(currency, this.paymentRemainder, 'amount', 'currency')
    },
    async getExchange (currency, obj, valueAmount, valueCurrency) {
      await this.$apollo.query({
        query: gql`query getOrders ($currency: Currency!, $value: Money! ) {
          pricing {
            convertMoney(currency: $currency, value: $value)
          }
        }`,
        variables: {
          currency: currency,
          value: obj[valueAmount] + ' ' + obj[valueCurrency]
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        obj[valueAmount] = Math.round(+data.data.pricing.convertMoney.amount * 100) / 100
        obj[valueCurrency] = data.data.pricing.convertMoney.currency
      }).catch((err) => {
        this.$emit('addSnack', 'item.name', 'Error', err)
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

</style>
