<template>
  <v-col cols="12">
    <v-btn
      color="#010820"
      v-if="this.selectedProjectType.length"
      class="rounded-lg fz-13 fw-600 text-none ml-auto align-self-end mb-4"
      :class="{'mt-4': $vuetify.breakpoint.xsOnly}"
      height="30"
      @click="selectProjectType(null, true)"
      outlined
    >
      Reset statuses
    </v-btn>
    <v-sheet
      color="transparent"
      elevation="0"
      class="d-flex flex-wrap dashboard"
    >
      <v-card
        v-for="(item, index) in dashboardStatistics" :key="index"
        class="rounded-lg d-flex flex-column dashboard-item fz-14 border-lightgray rounded-2"
        @click="selectProjectType(item.status)"
        :ripple="false"
        :class="{'small': $vuetify.breakpoint.lgAndDown, 'dashboard-item--active': selectedProjectType.find(el => el.id === item.status.id)}"
        elevation="0"
      >
        <span class="mb-1 text-gray font-weight-medium">{{ item.status.label }}</span>
        <div class="d-flex">
          <v-sheet
            class="rounded-circle d-flex align-center justify-center mr-2"
            :style="{'background-color': `${item.status.color}`, 'box-shadow': `0px 3px 6px ${item.status.color}`}"
            height="42"
            min-height="42"
            min-width="42"
            width="42"
          >
                <span class="fz-16 fw-600 lh-22 white--text">
                  {{ item.count }}
                </span>
          </v-sheet>
          <v-row no-gutters class="flex-column justify-space-between">
            <span>{{ Math.round(item.valueUsd) | numberFormat }}$</span>
            <span>{{ Math.round(item.valueRub) | numberFormat }}₽</span>
          </v-row>
        </div>
      </v-card>
    </v-sheet>
    <Snackbar :messages="snackbarMessage"/>
  </v-col>
</template>

<script>
import gql from 'graphql-tag'
import Snackbar from '@/components/App/Functional/Snackbar'
import numberFormat from '@/helpers/numberFormat'
import { debounce } from 'lodash'

export default {
  name: 'DashboardOrder',
  filters: {
    numberFormat
  },
  components: {
    Snackbar
  },
  props: {
    dateStart: {
      type: String,
      default: ''
    },
    dateEnd: {
      type: String,
      default: ''
    },
    searchClient: {
      type: String,
      default: ''
    },
    searchCompany: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedProjectType: [],
      dashboardStatistics: [],
      snackbarMessage: []
    }
  },
  methods: {
    formatToDateTime(date) {
      return date
    },
    getOrdersStatistics: debounce(async function () {
      await this.$apollo.query({
        query: gql`query getStatisticsForOrderDashboard($periodStart: datetime!, $periodEnd: datetime!, $searchCompany: String, $searchClient: String) {
          design {
            orders {
              orderStatistics(input: {periodStart: $periodStart, periodEnd: $periodEnd, searchCompany: $searchCompany, searchClient: $searchClient}) {
                statuses {
                  count
                  status
                  valueUsd
                  valueRub
                }
              }
            }
          }
        }`,
        variables: {
          periodStart: this.dateStart ? this.formatToDateTime(this.dateStart) : '2000-01-01',
          periodEnd: this.dateEnd ? this.formatToDateTime(this.dateEnd) : '2099-01-01',
          searchCompany: this.searchCompany,
          searchClient: this.searchClient
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.dashboardStatistics = data.data.design.orders.orderStatistics.statuses
        if (this.$store.getters.getStatusLabels) {
          this.dashboardStatistics.forEach(el => {
            this.$store.getters.getStatusLabels.forEach(subEl => {
              if (el.status === subEl.value) {
                el.status = subEl
              }
            })
          })
        }
      }).catch((err) => {
        this.snackbarMessage = ['Error', err]
        console.log(err)
      })
    }, 1000, { leading: false, trailing: true }),
    selectProjectType(status, reset) {
      if (reset) {
        this.selectedProjectType = []
        this.$emit('ordersUpdate', this.selectedProjectType)
        return
      }
      if (!this.selectedProjectType.length) {
        this.selectedProjectType.push(status)
      } else {
        if (this.selectedProjectType.find(el => el.id === status.id)) {
          this.selectedProjectType.splice(this.selectedProjectType.findIndex(el => el.id === status.id), 1)
        } else {
          this.selectedProjectType.push(status)
        }
      }
      this.$emit('ordersUpdate', this.selectedProjectType)
    },
    async checkStatusLabels () {
      this.$store.getters.getStatusLabels ? this.statusLabels = this.$store.getters.getStatusLabels : await this.getStatusLabels()
    },
    async getStatusLabels () {
      await this.$apollo.query({
        query: gql`query getStatusLabels {
          design {
            orders {
              orderStatusLabels {
                id
                label
                value
              }
            }
          }
        }`,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.$store.commit('updateStatusLabels', data.data.design.orders.orderStatusLabels)
      }).catch((err) => {
        this.snackbarMessage = ['Error', err]
        console.log(err)
      })
    }
  },
  created() {
    this.checkStatusLabels()
    this.getOrdersStatistics()
  },
  watch: {
    dateStart: {
      handler() {
        this.getOrdersStatistics()
      }
    },
    dateEnd: {
      handler() {
        this.getOrdersStatistics()
      }
    },
    searchClient: {
      handler() {
        this.getOrdersStatistics()
      }
    },
    searchCompany: {
      handler() {
        this.getOrdersStatistics()
      }
    }
  }
}
</script>

<style lang="scss">
.dashboard {
  &-item {
    flex: 1 0 20%;
    margin: 4px;
    padding: 8px;
    border: 1px solid #EEF2F7;

    &--active {
      background-color: #EEF2F7 !important;
    }
  }
}
</style>
