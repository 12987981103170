<template>
  <v-col class="d-flex flex-column height-100 pa-0" v-if="priceInfo">
    <v-row class="bb-gray px-7 py-3 ma-0 flex-grow-0 d-flex align-center justify-space-between" style="max-height: 70px; min-height: 70px">
      <span class="fz-16 font-weight-bold text-black" style="margin-left: 2px">
        Payments history
      </span>
      <v-icon color="#010820" class="rounded-circle chevron-right" @click="$emit('closePaymentAdditional')">mdi-chevron-right</v-icon>
    </v-row>
    <v-row class="ma-0 flex-grow-1 scroll">
      <v-col style="padding: 30px !important;">
        <v-row class="ma-0">
          <span class="fz-14 font-weight-bold">Paid up</span>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-0 mb-4" v-for="payment in operations" :key="payment.id">
              <v-col class="pa-0">
                <span class="fz-14 text-gray">Date of payment</span>
                <v-text-field
                  :value="formatDate(payment.date)"
                  readonly
                  filled
                  hide-details
                  class="rounded-lg mt-1 border-none fz-14 font-weight-medium text-black"
                  dense
                ></v-text-field>
              </v-col>
              <v-col class="py-0 px-3">
                <span class="fz-14 text-gray">Payment</span>
                <v-text-field
                :value="paymentValue(payment.moneyIn.amount, payment.moneyIn.symbol)"
                readonly
                filled
                hide-details
                class="rounded-lg mt-1 border-none fz-14 font-weight-medium text-black"
                dense
              ></v-text-field>
              </v-col>
              <v-col cols="1" class="pa-0">
                <v-row class="ma-0 height-100">
                  <v-dialog
                  transition="dialog-bottom-transition"
                  max-width="370"
                  content-class="rounded-xl"
                  overlay-color="rgba(9, 24, 73, 0.2)"
                  overlay-opacity="1"
                >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      elevation="0"
                      x-small
                      icon
                      fab
                      class="mt-auto mb-1"
                    >
                      <v-icon size="20"  class="stroke-gray">$delete</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card class="pa-7">
                      <v-card-text class="py-0">
                        <div class="text-black font-weight-medium fz-18 text-center">Do you really want to delete payment {{ payment.moneyIn.symbol !== '₽' ? payment.moneyIn.symbol : '' }}{{ +payment.moneyIn.amount | numberFormat }}{{ payment.moneyIn.symbol === '₽' ? payment.moneyIn.symbol : '' }}?</div>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions class="justify-space-between pa-0">
                        <v-btn
                          class="rounded-lg text-none fz-16 fw-600"
                          outlined
                          width="150"
                          height="40"
                          @click="dialog.value = false"
                        >Return</v-btn>
                        <v-btn
                          elevation="0"
                          color="#FF0000"
                          width="150"
                          height="40"
                          class="rounded-lg white--text text-none fz-16 fw-600"
                          @click="removePayment(payment.id)"
                        >
                          <v-icon left class="stroke-white">
                            $delete
                          </v-icon>
                          Delete</v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
                </v-row>
              </v-col>
            </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { Locale } from 'v-calendar'
import numberFormat from '@/helpers/numberFormat'
const locale = new Locale()

export default {
  name: 'PaymentsHistory',
  props: ['priceInfo'],
  filters: {
    numberFormat
  },
  methods: {
    formatDate (date) {
      return locale.format(date, 'DD.MM.YYYY')
    },
    paymentValue (amount, symbol) {
      return (symbol !== '₽' ? symbol : '') + this.$options.filters.numberFormat(+amount) + (symbol === '₽' ? symbol : '')
    },
    removePayment (id) {
      this.$emit('removePayment', id)
    }
  },
  computed: {
    operations() {
      return this.priceInfo.operations.filter(el => el.moneyIn)
    }
  }
}
</script>

<style scoped>

</style>
